import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import intersection from "../../Assets/Projects/104intersection.png";
import bombyx from "../../Assets/Projects/106bombyx.png";
import titration from "../../Assets/Projects/109titration.png";
import Garbage_classification from "../../Assets/Projects/Garbage_classification.png";
import minishell from "../../Assets/Projects/minishell.png";
import my_rpg from "../../Assets/Projects/my_rpg.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={titration}
              isBlog={false}
              title="109titration"
              description="Developed '109titration', a program for analyzing pH titration data to determine food preservative concentrations in beverages. Implemented numerical methods to calculate derivatives, estimate equivalence points, and perform linear interpolation on titration curves. Showcased skills in data analysis, mathematical modeling, and software development, with a focus on applying calculus concepts to real-world analytical chemistry problems."
              ghLink="https://github.com/yahia-adam/109titration"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bombyx}
              isBlog={false}
              title="106bombyx"
              description="Developed '106bombyx', a program to model and analyze the population dynamics of butterflies using chaos theory principles. Implemented a mathematical model to calculate and plot butterfly population growth over generations, considering factors like initial population and growth rate. The project demonstrated skills in numerical computing, data visualization, and the application of mathematical concepts to biological systems, producing output compatible with graphing tools like gnuplot."
              ghLink="https://github.com/yahia-adam/106bombyx"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={intersection}
              isBlog={false}
              title="104intersection"
              description="Developed '104intersection', a program to calculate intersection points between light rays and 3D geometric shapes (spheres, cylinders, and cones) for ray tracing applications. Implemented mathematical models to solve 3D quadratic equations, determining intersection points between a line and various surfaces. This project demonstrated proficiency in 3D geometry, vector mathematics, and computational problem-solving, with practical applications in computer graphics and image synthesis."
              ghLink="https://github.com/yahia-adam/104intersection"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Garbage_classification}
              isBlog={false}
              title="3BigData"
              description="Implemented various machine learning models from scratch for garbage classification, demonstrating deep understanding of underlying algorithms. Applied these models to a real-world environmental challenge, showcasing ability to translate theoretical knowledge into practical solutions. Conducted extensive experimentation with different models to optimize performance, highlighting skills in data analysis, model evaluation, and iterative improvement in machine learning projects."
              ghLink="https://github.com/yahia-adam/3BigData"
              demoLink="https://3bigddata-doc-yahia-abdchafee-adam-a6db48aeeb6d15e1e10cba519f27.gitlab.io/documentation.html"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={my_rpg}
              isBlog={false}
              title="My rpg"
              description="Developed a complete Role-Playing Game (RPG) in C using the CSFML library, demonstrating proficiency in low-level programming and game development. Designed and implemented complex systems including playable characters, monsters, and a quest system, showcasing skills in game design and state management. The project highlighted capabilities in performance optimization, memory management, and creation of interactive user interfaces in a 2D game development environment"
              ghLink="https://github.com/yahia-adam/MY_RPG"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={minishell}
              isBlog={false}
              title="Minishell"
              description="Developed a custom C shell based on tcsh, capable of executing basic commands and managing its own environment. Implemented features including multiple command execution with separators and built-in commands, demonstrating proficiency in systems programming and Unix-like operating systems. The project showcased skills in low-level programming, process management, and creating interactive command-line interfaces, while adhering to the functionality of standard Unix shells."
              ghLink="https://github.com/yahia-adam/minishell2"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
